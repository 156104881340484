import React from 'react'

import BlockContent from '../components/block-content'



import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const ParagraphClass = 'inline-block relative w-full text-center px-10 md:px-0  md:text-base text-white'

const PageHeader = props => {
  const { textAlign, pageClass, title, image, body, full } = props

  const haveImage = image && image.asset ? true : false

  return (
    <div className="flex flex-col justify-center items-center pt-10 my-6 relative w-full">
      <h1
        className={` relative mx-10 px-4 pb-2 border-b-4 mb-4 text-center text-4xl  max-w-md md:max-w-xl  md:text-5xl font-semibold leading-tight ${
          haveImage ? 'mb-0' : 'mb-8'
        }`}
      >
        {title}
      </h1>
      {haveImage ? (
        <img
          className={` w-full fit rounded-sm my-8 ${full ? 'w-full' : 'max-w-2xl'}`}
          src={imageUrlFor(buildImageObj(image)).url()}
        />
      ) : null}
      {body ? (
        <div
          className={` relative ${textAlign ? textAlign : ''} ${
            pageClass ? pageClass : ''
          }`}
        >
          <div className={ParagraphClass}>
            <BlockContent blocks={body || []} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PageHeader
